$(window).ready(function(){

	function init(){
		menu();

		home();
	}

	function menu(){
		$('#header .menu-button').click(function(e){
			e.preventDefault();
			$('#header nav').toggleClass('closed');
		})
	}

	function home(){
		$('#blocks').slick({
			dots: false,
			infinite: true,
			draggable: false,
			speed: 500,
			slidesToShow: 3,
			adaptiveHeight: false,
			slidesToScroll: 1,
			autoplaySpeed: 6000,
			autoplay: true,
			prevArrow: false,
    		nextArrow: false,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				}
			]
		});
	}

	init();
});

$(window).load(function(){

});


window.initMap = function(){
	window.locations = [];

	if($('#google-map').length){
		window.initMap = null;

		var map = new google.maps.Map(document.getElementById('google-map'), {
	      	zoom: 9
	    });

	    map.setCenter(new google.maps.LatLng(51.036252, 3.108814));

	    $('#submit-search').click(function(e) {
	    	e.preventDefault();
	      	geocodeAddress(map);
	    });

	    if($('#zip').val() !== '' && $('#zip').val() !== null){
	    	geocodeAddress(map);
	    }

	    placeMarkers(map);
	}
};

window.geocodeAddress = function(map) {
	var json = JSON.parse($('#points-data').html());
	var geocoder = new google.maps.Geocoder();
	var reg = new RegExp('^[0-9]+$');
	
	if($('#zip').val() == ''){
		$('#search .error').removeClass('hidden');
		//$('tbody tr.hidden').removeClass('hidden');
	}else{
		$('#search .error').addClass('hidden');
		//$('tbody tr[zip!="'+$('#zip').val()+'"]').addClass('hidden');

	  	var address = $('#zip').val()+', Belgium';

	  	geocoder.geocode({'address': address}, function(results, status) {
	        if (status === 'OK') {
	          	map.setZoom(13);
	          	findClosestPin(results, map);
	        } else {
	          	alert('Geocode was not successful for the following reason: ' + status);
	        }
	  	});
	}
};

window.findClosestPin = function(results, map){
 	var closest = 0;
 	var allplaces = [];

	$.each(window.locations, function(i, e){
		var distance = google.maps.geometry.spherical.computeDistanceBetween(e.pos,results[0].geometry.location);
		e.distance = distance;

		allplaces.push(e);

	    if(closest.distance > distance || closest === 0){
	    	closest = e;
	    }
	});

	map.setCenter(closest.pos);
}

window.placeMarkers = function(map) {
	var json = JSON.parse($('#points-data').html());
	var icons = {
	  point: {
	    icon: '/assets/images/pin.png'
	  }
	};
	window.count = 0;

	var markers = [];
	var markerCluster = new MarkerClusterer(map, markers,
            {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

	for (var i = 0; i < json.points.length; i++) {
		var address = json.points[i].address + ', ' + json.points[i].zip + ' ' + json.points[i].city;
		var name = json.points[i].name;
		var content = '<div class="marker"><strong>' + name.replace('-', ' ') + '</strong><p>' + json.points[i].address + ', <br/>' + json.points[i].zip + ' ' + json.points[i].city + '<br/>' + json.points[i].telephone + '</p></div>';
		setMarker(map, json, icons, i, content, address, json.points[i], markerCluster);
  	}
  	//console.log('aantal bakkers: '+window.count);
};

window.setMarker = function(map, json, icons, i, content, address, point, markerCluster) {
	var geocoder = new google.maps.Geocoder();
	var infowindow = new google.maps.InfoWindow();
	if(point['lat'] == "" || point['lng'] == ""){
		geocoder.geocode({'address': address}, function(results, status) {
		    if (status === 'OK') {
		     	var marker = new google.maps.Marker({
		      		map: map,
		      		position: results[0].geometry.location,
		      		icon: icons.point.icon
		     	});

		     	markerCluster.addMarker(marker);

		     	window.locations.push({'pos' : results[0].geometry.location, 'content':content});

		     	google.maps.event.addListener(marker, 'click', function() {
		     		infowindow.setContent(content);
			      	infowindow.open(map, this);
			    });

		     	point['lat'] = results[0].geometry.location.lat();
		     	point['lng'] = results[0].geometry.location.lng();

			    $.ajax({
				  	url: '/ajax',
				  	data : {'pointData': point},
				  	type: 'POST'
				}).done(function(e) {
				  	console.log(e, 'ajax done');
				});
		    }else{
		    	if(status == 'ZERO_RESULTS'){
			    	point['lat'] = 0;
			     	point['lng'] = 0;

				    $.ajax({ 
					  	url: '/ajax',
					  	data : {'pointData': point},
					  	type: 'POST'
					}).done(function(e) {
					  	console.log(e, 'ajax done');
					});
		    	}
		      	console.log('Geocode was not successful for the following reason: ' + status, point);
		    }
		});
	}else{
		window.count++;
		var marker = new google.maps.Marker({
			map: map,
			position: new google.maps.LatLng(point['lat'], point['lng']),
			icon: icons.point.icon
		});

		markerCluster.addMarker(marker);

		window.locations.push({'pos' : new google.maps.LatLng(point['lat'], point['lng']), 'content':content});

		google.maps.event.addListener(marker, 'click', function() {
			infowindow.setContent(content);
		  	infowindow.open(map, this);
		});
	}
};
